import { useEffect } from 'react'

import { captureMessage } from '@sentry/browser'

const useScrollToAnchor = (delay = 500, maxRetries = 5) => {
  useEffect(() => {
    const scrollToAnchor = (attempt = 0) => {
      const anchor = window?.location?.hash?.substring(1)

      if (anchor) {
        const element = document.getElementById(anchor)

        if (element) {
          setTimeout(() => {
            element.scrollIntoView({ behavior: 'smooth' })
          }, delay)
        } else if (attempt < maxRetries) {
          // Retry after a delay if the element is not found
          setTimeout(() => scrollToAnchor(attempt + 1), delay)
        } else {
          // Capture error if the element still cannot be found after retries
          captureMessage('Failed to find the element to scroll to: ', {
            extra: { anchor },
          })
        }
      }
    }

    // Initial scroll to anchor
    scrollToAnchor()

    // Scroll on hash change
    const handleHashChange = () => scrollToAnchor()

    window.addEventListener('hashchange', handleHashChange)

    return () => {
      window.removeEventListener('hashchange', handleHashChange)
    }
  }, [delay, maxRetries])
}

export default useScrollToAnchor

import { Dispatch, SetStateAction } from 'react'

import {
  FindSettingsClientConfigurationQuery,
  FindSettingsClientConfigurationQueryVariables,
} from 'types/graphql'

import { CellSuccessProps } from '@redwoodjs/web'

import { default as LoadingSpinner } from 'src/components/Library/Loading'
import SettingsClientConfiguration from 'src/components/SettingsClientConfiguration'

export const beforeQuery = (props) => ({
  variables: props,
  fetchPolicy: 'no-cache',
  nextFetchPolicy: 'no-cache',
})

export const QUERY = gql`
  query FindSettingsClientConfigurationQuery {
    settings: currentClient {
      id
      name
      organisation {
        organisation {
          name
        }
      }
      logoUrl
      logoStorageObject {
        id
        downloadUrl
      }
      logomarkUrl
      logomarkStorageObject {
        id
        downloadUrl
      }
      primaryColor
      secondaryColor
      headerUrl
      headerStorageObject {
        id
        downloadUrl
      }
      headerType
      notificationEmail
      onboardingProgress {
        clientSettings
        memberships
        membershipGroups
        homeLinks
        integrations
      }
      knowledgeBaseUrl
      knowledgeBaseStorageObject {
        id
        downloadUrl
      }
    }
  }
`

export const Loading = () => (
  <div className="h-96">
    <LoadingSpinner />
  </div>
)

export const Empty = () => <div>Empty</div>

export const Failure = ({ error }) => (
  <div style={{ color: 'red' }}>Error: {error.message}</div>
)

type Props = CellSuccessProps<
  FindSettingsClientConfigurationQuery,
  FindSettingsClientConfigurationQueryVariables
> & {
  setContinueOnboarding?: Dispatch<SetStateAction<boolean>>
}
export type Settings = Props['settings']

export const Success = ({ settings, setContinueOnboarding = null }) => {
  const onboardingProgress = settings?.onboardingProgress

  // If any of the onboarding items are incomplete, show the continue prompt
  if (
    !onboardingProgress?.clientSettings ||
    !onboardingProgress?.memberships ||
    !onboardingProgress?.membershipGroups ||
    !onboardingProgress?.homeLinks ||
    !onboardingProgress?.integrations
  ) {
    setContinueOnboarding && setContinueOnboarding(true)
  }

  return <SettingsClientConfiguration settings={settings} />
}
